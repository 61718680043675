{
  "name": "madrasa",
  "version": "0.4.83",
  "scripts": {
    "ng": "ng",
    "start": "ng serve --port 4210",
    "build": "ng build",
    "watch": "ng build --watch --configuration development",
    "test": "ng test",
    "format": "prettier --write \"**/*.{js,mjs,ts,mts,d.ts,html}\"",
    "lint": "ng lint"
  },
  "private": true,
  "dependencies": {
    "@angular-architects/module-federation": "^19.0.3",
    "@angular/animations": "^19.2.4",
    "@angular/cdk": "^19.2.7",
    "@angular/common": "^19.2.4",
    "@angular/compiler": "^19.2.4",
    "@angular/core": "^19.2.4",
    "@angular/forms": "^19.2.4",
    "@angular/platform-browser": "^19.2.4",
    "@angular/platform-browser-dynamic": "^19.2.4",
    "@angular/router": "^19.2.4",
    "@angular/service-worker": "^19.2.4",
    "@apollo/client": "^3.13.5",
    "@jsverse/transloco": "^7.6.1",
    "@ngneat/effects": "^2.1.2",
    "@ngneat/effects-ng": "^3.1.4",
    "@ngneat/elf": "^2.5.1",
    "@ngneat/elf-cli-ng": "^1.0.0",
    "@ngneat/elf-devtools": "^1.3.0",
    "@ngneat/elf-entities": "^5.0.2",
    "@ngneat/elf-pagination": "^1.1.0",
    "@ngneat/elf-persist-state": "^1.2.1",
    "@ngneat/elf-requests": "^1.9.2",
    "@ngneat/elf-state-history": "^1.4.0",
    "@ngneat/until-destroy": "^10.0.0",
    "@ngx-formly/core": "^6.3.12",
    "@primeng/themes": "^19.0.10",
    "angular-calendar": "^0.31.1",
    "apollo-angular": "^10.0.3",
    "chardet": "^2.1.0",
    "date-fns": "^4.1.0",
    "date-fns-tz": "^3.2.0",
    "filesize": "^10.1.6",
    "graphql": "^16.10.0",
    "graphql-ws": "^6.0.4",
    "keycloak-angular": "^19.0.2",
    "keycloak-js": "^26.2.0",
    "lodash-es": "^4.17.21",
    "ngx-iban-validator": "^1.2.2",
    "ngx-permissions": "^19.0.0",
    "ngx-quill": "^27.0.1",
    "primeclt": "^0.1.5",
    "primeicons": "^7.0.0",
    "primeng": "^19.0.10",
    "rrule": "^2.8.1",
    "rxjs": "~7.8.2",
    "tailwindcss-primeui": "^0.6.1",
    "tslib": "^2.8.1",
    "uuid": "^11.1.0",
    "xlsx": "https://cdn.sheetjs.com/xlsx-0.20.1/xlsx-0.20.1.tgz",
    "zone.js": "~0.15.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^19.2.5",
    "@angular-eslint/builder": "19.3.0",
    "@angular-eslint/eslint-plugin": "19.3.0",
    "@angular-eslint/eslint-plugin-template": "19.3.0",
    "@angular-eslint/schematics": "19.3.0",
    "@angular-eslint/template-parser": "19.3.0",
    "@angular/cli": "^19.2.5",
    "@angular/compiler-cli": "^19.2.4",
    "@types/jasmine": "~5.1.7",
    "@types/lodash-es": "^4.17.12",
    "@typescript-eslint/eslint-plugin": "8.28.0",
    "@typescript-eslint/parser": "8.28.0",
    "autoprefixer": "^10.4.21",
    "eslint": "9.23.0",
    "eslint-config-prettier": "^10.1.1",
    "eslint-import-resolver-typescript": "^4.2.5",
    "eslint-plugin-import": "^2.31.0",
    "eslint-plugin-prefer-arrow": "^1.2.3",
    "eslint-plugin-prettier": "^5.2.5",
    "jasmine-core": "~5.6.0",
    "karma": "~6.4.4",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.1",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "ng-packagr": "^19.2.0",
    "postcss": "^8.5.3",
    "prettier": "^3.5.3",
    "prettier-plugin-tailwindcss": "^0.6.11",
    "tailwindcss": "^3.4.17",
    "typescript": "~5.7.3"
  },
  "scarfSettings": {
    "defaultOptIn": false
  }
}
