import { inject, Injectable, signal } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LoggerService } from './logger.service';

@UntilDestroy({ checkProperties: true })
@Injectable({ providedIn: 'root' })
export class VersionService {
  private loggerService = inject(LoggerService);
  private swUpdate = inject(SwUpdate);

  readonly isNewVersionAvailable = signal(false);
  constructor() {
    const swUpdate = this.swUpdate;

    if (!this.swUpdate.isEnabled) {
      console.info('Service worker updates are disabled.');
      return;
    }
    console.info('Service worker updates are enabled.');
    swUpdate.versionUpdates.pipe(untilDestroyed(this)).subscribe(event => {
      if (event.type === 'VERSION_DETECTED') {
        this.isNewVersionAvailable.set(true);
      }
    });
  }
  applyUpdate(): void {
    // Reload the page to update to the latest version after the new version is activated
    this.swUpdate
      .activateUpdate()
      .then(() => document.location.reload())
      .catch(error => {
        console.error('Failed to apply updates:', error);
        this.loggerService.error(error?.message, error?.stack);
      });
  }
}
